import ApolloClient from "apollo-boost";

import React, {Component} from "react";
import { render } from "react-dom";

import { ApolloProvider } from "react-apollo"
import { apiUri} from './config'
import App from './App'
import './index.css';
import {setGlobal} from 'reactn'

setGlobal({
  searchString: ''
});


const client = new ApolloClient({ uri: apiUri });


class Index extends Component{
render(){
    return(
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    )}
}

render(<Index />, document.getElementById("root"));
