import React from 'react'
import { useGlobal } from 'reactn';
import { GET_TOMATO } from '../queries'
import { Query } from 'react-apollo'
import LazyLoad from 'react-lazyload';


function Tomato(props) {
  const { name, text } = props.data
  const imgUrl = '/images/impecta/500.jpg'

  console.log(props.data)
  return (
    <div style={{ maxWidth: 400, margin: 'auto', padding: 20, marginTop: 10, textAlign: 'left' }}>
      <h3>Beskrivning</h3>
      <div>{text}</div>
      <h3>Betyg</h3>
      <div><i>Massa betyg!</i></div>
      <h3>Bilder</h3>
      <div><i>Kommer snart!</i></div>
      <LazyLoad height={200}>
        <img style={{ width: 200, height: 200 }} src={imgUrl} />
      </LazyLoad>
    </div>
  )
}

export default function () {
  const [name] = useGlobal('tomato');

  return (
    <Query query={GET_TOMATO} variables={{ name }} >
      {({ loading, error, data }) => {
        if (loading) return <div></div>
        if (error) return <div>Error {error}</div>
        console.log(data)
        return <Tomato data={data.tomato} />
      }}
    </Query>
  )
}