import gql from 'graphql-tag'


export const FIND_TOMATO = gql`
  query search($name: ID) {
    search(name: $name) {
      name
      tags
    }
  }
`;

export const GET_TOMATOES = gql`
  {
    tomatoes {
      name
    }
  }
`;

export const GET_TOMATO = gql`
  query GetTomato($name: ID) {
    tomato(name: $name) {
      name
      text
      tags
    }
  }
`;