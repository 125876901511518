import React from 'react';
import { useGlobal } from 'reactn';


function Search(props){
    const [global, setGlobal] = useGlobal('searchString');
    
    return(
        <input 
            type="text" 
            value={global} 
            placeholder="hitta tomaten"
            autoFocus={true}
            onChange={ e => { setGlobal(e.currentTarget.value) }} />
    )
}

export default Search