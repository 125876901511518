import { useGlobal, setGlobal } from 'reactn';
import React from 'react';
import { Query } from 'react-apollo'
import {FIND_TOMATO} from '../queries'

function Item(props){
    const {name} = props.tomato

    function activate(){
        setGlobal({tomato:name, searchString:name})
    }
    return(
        <div 
            style={{cursor:'pointer', textDecoration:'underline', color:'#558B6E'}} 
            onClick={activate}>{name}
        </div>
    )
}

function SearchResult(){
    const [global] = useGlobal('searchString');

    return(
        <Query query={FIND_TOMATO} variables={{ name:global }} >
        {({ loading, error, data }) => {
          if (loading) return <div></div>
          if (error) return <div>Error {error}</div>

            console.log(data)

          const tomatoes = data.search
    
          return (
            <div style={{margin:30}}>
            { tomatoes.map((t,i) => <Item key={`t${i}`} tomato={t}></Item>) }
            </div>
          ) 
        }}
      </Query>
    )
}

export default SearchResult