
import React from 'react';
import './App.css';
import Search from './components/Search'
import SearchResult from './components/SearchResult'
import Tomato from './components/Tomato'
import Logo from './components/Logo'
import { useGlobal } from 'reactn';
import Progressbar from './components/Progressbar'

function App(){
  const [searchString] = useGlobal('searchString');
  const [tomato] = useGlobal('tomato');
  return(
    <div>
      <Logo />
      <Search />
      {searchString.length > 0 && <SearchResult />}

      {tomato && <Tomato />}
    </div>
  )
}

export default App